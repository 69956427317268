import React from 'react'
import PropTypes from 'prop-types'
import Pagination from '@material-ui/lab/Pagination'

function PaginationBlock(props) {
  const { data, size, callback } = props
  let nPages = 0
  let currentPage = 0
  if (data) {
    nPages = data.p.nPages
    currentPage = data.p.page
  }
  return (
    <div>
      {data && nPages > 1 && (
        <div style={{ marginBottom: '16px' }}>
          <Pagination
            variant="outlined"
            color="primary"
            page={currentPage}
            size={size}
            count={nPages}
            onChange={(e, newPage) => callback(newPage)}
          />
        </div>
      )}
    </div>
  )
}

PaginationBlock.defaultProps = {
  size: 'medium',
}

PaginationBlock.propTypes = {
  size: PropTypes.string,
  callback: PropTypes.func.isRequired,
  data: PropTypes.object.isRequired,
}

export default PaginationBlock
